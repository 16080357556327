import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import { SEO, Layout } from "../components/";
import { BoldText, Row } from "../components/styled";

const ContentContainer = styled(Row)`
  margin: 1rem;
`;

const Greeting = styled(BoldText)`
  font-size: 1.5rem;
  margin-top: 3rem;
`;

const keywords = [
  "selbstversorger",
  "selbstversorgerhaus",
  "tirol",
  "berge",
  "pitztal",
  "appartements",
  "ruhe",
  "genuss",
  "gletscher",
  "ski",
  "snowboard",
  "urlaub",
  "sommer",
  "winter",
  "sommerurlaub",
  "winterurlaub",
  "wellness",
  "gruppenhaus"
];

const IndexPage = ({ intl }) => {
  return (
    <Layout carousel>
      <SEO title={intl.formatMessage({ id: "title" })} keywords={keywords} />
      <ContentContainer>
        <div>
          <p>
            <FormattedMessage id="home_text" />
          </p>
          <p>
            <FormattedMessage id="home_text2" />
          </p>
          <p>
            <FormattedMessage id="home_text3" />
          </p>
          <Greeting>
            <FormattedMessage id="greeting" />
          </Greeting>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default injectIntl(IndexPage);
